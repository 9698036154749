export const candidateList: {
  name: string,
  placement: number,
  birthYear: number,
  familyStatus: string,
  job: string,
  district: string,
  image: string,
  statement: string,
  website_link: string,
  facebook_account: string,
  x_account: string,
  instagram_account: string,
}[] = [
  {
    name: 'Yvette Melchien',
    placement: 1,
    birthYear: 1986,
    familyStatus: 'Verheiratet, 2 Kinder',
    job: 'Lehrerin',
    district: 'Daxlanden, Grünwinkel, Mühlburg',
    image: '01 - Yvette Melchien.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Dr. Anton Huber',
    placement: 2,
    birthYear: 1987,
    familyStatus: 'Verheiratet, 1 Kind',
    job: 'Physiker, Ortsvorsteher',
    district: 'Wolfartsweier',
    image: '02 - Dr. Anton Huber.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Sibel<br>Uysal',
    placement: 3,
    birthYear: 1983,
    familyStatus: 'Ledig',
    job: 'Realschullehrerin',
    district: 'Grötzingen',
    image: '03 - Sibel Uysal.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Dr. Raphael Fechler',
    placement: 4,
    birthYear: 1969,
    familyStatus: 'In fester Partnerschaft lebend, 4 Kinder',
    job: 'Arzt / Facharzt für Urologie',
    district: 'Nordweststadt',
    image: '04 - Dr. Raphael Fechler.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Melis Schmid',
    placement: 5,
    birthYear: 1984,
    familyStatus: 'Verheiratet, 2 Kinder',
    job: 'Stellvertretende Abteilungsleiterin im Öffentlichen Dienst',
    district: 'Rüppurr',
    image: '05 - Melis Schmid.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Mathias Tröndle',
    placement: 6,
    birthYear: 1956,
    familyStatus: 'Großvater zweier Enkel',
    job: 'Journalist',
    district: 'Durlach',
    image: '06 - Mathias Tröndle.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Elke Ernemann',
    placement: 7,
    birthYear: 1959,
    familyStatus: '2 Kinder, 2 Enkelkinder',
    job: 'Ortsvorsteherin',
    district: 'Hohenwettersbach',
    image: '07 - Elke Ernemann.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Dr. Oliver Langewitz',
    placement: 8,
    birthYear: 1976,
    familyStatus: 'Verheiratet',
    job: 'Soziologe',
    district: 'Knielingen',
    image: '08 - Dr. Oliver Langewitz.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Irene<br>Moser',
    placement: 9,
    birthYear: 1957,
    familyStatus: 'Verheiratet, 1 Sohn',
    job: 'Ehemalige Lehrerin',
    district: 'Neureut',
    image: '09 - Irene Moser.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Artur Budnik',
    placement: 10,
    birthYear: 1977,
    familyStatus: 'Verheiratet, 2 Kinder',
    job: 'Jobcoach (HWK / Lebenshilfe)',
    district: 'Innenstadt-Ost, Innenstadt-West, Südweststadt',
    image: '10 - Artur Budnik.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Paulina Miliczek',
    placement: 11,
    birthYear: 1997,
    familyStatus: 'Verheiratet',
    job: 'Doktorandin und Dozentin im Bereich Interkulturelle Pädagogik und Bildungsgerechtigkeit',
    district: 'Neureut',
    image: '11 - Paulina Miliczek.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Michael<br>Zeh',
    placement: 12,
    birthYear: 1956,
    familyStatus: 'Ledig',
    job: 'Rentner',
    district: 'Südstadt',
    image: '12 - Michael Zeh.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Carina Perez',
    placement: 13,
    birthYear: 1995,
    familyStatus: 'Ledig',
    job: 'Referentin für europäische Wirtschafts- und Finanzpolitik',
    district: 'Innenstadt-Ost, Innenstadt-West, Südweststadt',
    image: '13 - Carina Perez.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Adrian Keller',
    placement: 14,
    birthYear: 2000,
    familyStatus: 'Ledig',
    job: 'Informatikstudent',
    district: 'Weststadt, Nordstadt',
    image: '14 - Adrian Keller.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',/*
    statement: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    website_link: 'adrian-keller.de',
    facebook_account: 'AdrianNKeller',
    x_account: 'adriannkeller',
    instagram_account: 'adriannkeller',*/
  },
  {
    name: 'Maren Göppert',
    placement: 15,
    birthYear: 1974,
    familyStatus: 'Verheiratet, 2 Kinder',
    job: 'Lehrerin',
    district: 'Rintheim',
    image: '15 - Maren Göppert.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Felix<br>Metzke',
    placement: 16,
    birthYear: 1997,
    familyStatus: 'Verheiratet',
    job: 'Dualer Student / Soziale Arbeit',
    district: 'Hagsfeld, Waldstadt',
    image: '16 - Felix Metzke.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Zahide<br>Yesil',
    placement: 17,
    birthYear: 1970,
    familyStatus: 'Geschieden, 1 Kind',
    job: 'Bürokauffrau',
    district: 'Durlach',
    image: '17 - Zahide Yesil.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Nicolai Friedrich',
    placement: 18,
    birthYear: 1993,
    familyStatus: 'Ledig',
    job: 'Chemisch-technischer Assistent',
    district: 'Oberreut',
    image: '18 - Nicolai Friedrich.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Yasmin Schweiger',
    placement: 19,
    birthYear: 1994,
    familyStatus: 'Ledig',
    job: 'Rechtsanwältin',
    district: 'Südstadt',
    image: '19 - Yasmin Schweiger.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Alexander Linder',
    placement: 20,
    birthYear: 1999,
    familyStatus: 'Ledig',
    job: 'Informatiker',
    district: 'Rüppurr',
    image: '20 - Alexander Linder.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Hanne Mutter',
    placement: 21,
    birthYear: 1984,
    familyStatus: 'Verheiratet, 2 Kinder',
    job: 'Verwaltungsjuristin',
    district: 'Innenstadt-Ost, Innenstadt-West, Südweststadt',
    image: '21 - Hanne Mutter.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Samuel Schrank',
    placement: 22,
    birthYear: 1998,
    familyStatus: 'Ledig',
    job: 'Student',
    district: 'Hagsfeld, Waldstadt',
    image: '22 - Samuel Schrank.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Ronja Schwarz',
    placement: 23,
    birthYear: 2001,
    familyStatus: 'Ledig',
    job: 'Studentin',
    district: 'Grötzingen',
    image: '23 - Ronja Schwarz.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Eric Lehmann',
    placement: 24,
    birthYear: 1996,
    familyStatus: 'Ledig',
    job: 'Mitarbeiter Bundeswehr (Lagerwirtschaft)',
    district: 'Daxlanden, Grünwinkel, Mühlburg',
    image: '24 - Eric Lehmann.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Firdevs Kaplaner',
    placement: 25,
    birthYear: 1977,
    familyStatus: 'Verheiratet',
    job: 'Pädagogin in Leitungsfunktion',
    district: 'Durlach',
    image: '25 - Firdevs Kaplaner.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Christoph Elsner',
    placement: 26,
    birthYear: 1992,
    familyStatus: 'Ledig',
    job: 'Ingenieur',
    district: 'Nordweststadt',
    image: '26 - Christoph Elsner.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Karin Weisser',
    placement: 27,
    birthYear: 1964,
    familyStatus: '2 Kinder',
    job: 'Arzthelferin',
    district: 'Daxlanden, Grünwinkel, Mühlburg',
    image: '27 - Karin Weisser.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Davut Deligöz',
    placement: 28,
    birthYear: 1970,
    familyStatus: 'Verheiratet, 3 Kinder',
    job: 'Kfz-Meister',
    district: 'Rintheim',
    image: '28 - Davut Deligöz.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Natascha Roth',
    placement: 29,
    birthYear: 1972,
    familyStatus: 'In Partnerschaft lebend, 2 Kinder (10 Jahre und 14 Jahre)',
    job: 'Bauingenieurin',
    district: 'Weststadt, Nordstadt',
    image: '29 - Natascha Roth.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Harry Clausnizer',
    placement: 30,
    birthYear: 1987,
    familyStatus: 'Verheiratet, keine Kinder',
    job: 'Heimleiter Studierendenwohnheim',
    district: 'Innenstadt-Ost, Innenstadt-West, Südweststadt',
    image: '30 - Harry Clausnizer.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Yasemin Güler',
    placement: 31,
    birthYear: 1981,
    familyStatus: 'Verheiratet, 2 Kinder',
    job: 'Controllerin',
    district: 'Daxlanden, Grünwinkel, Mühlburg',
    image: '31 - Yasemin Güler.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Edgardo Cañas Kurz',
    placement: 32,
    birthYear: 1990,
    familyStatus: 'Ledig',
    job: 'Akademischer Mitarbeiter',
    district: 'Innenstadt-West',
    image: '32 - Dr. Edgardo Cañas Kurz.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Barbara Mehnert',
    placement: 33,
    birthYear: 1958,
    familyStatus: 'Verheiratet',
    job: 'Dipl. Sozialarbeiterin / Sozialwirtin',
    district: 'Südstadt',
    image: '33 - Barbara Mehnert.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Axel<br>Wrtal',
    placement: 34,
    birthYear: 1970,
    familyStatus: 'Verheiratet, 2 Kinder',
    job: 'Sozialversicherungsfachangestellter',
    district: 'Beiertheim, Bulach',
    image: '34 - Axel Wrtal.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Angela Hölzer',
    placement: 35,
    birthYear: 1955,
    familyStatus: 'Verheiratet, 1 Kind',
    job: 'Rektorin a.D.',
    district: 'Knielingen',
    image: '35 - Angela Hölzer.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Arno Zürcher',
    placement: 36,
    birthYear: 1966,
    familyStatus: 'Verheiratet, 2 erwachsene Töchter',
    job: 'Ingenieur und Leitender Landwirtschaftsdirektor',
    district: 'Oststadt',
    image: '36 - Arno Zürcher.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Dr. Brigitte Schmid-Hagenmeyer',
    placement: 37,
    birthYear: 1978,
    familyStatus: 'Verheiratet, 3 Kinder',
    job: 'Psychologische Psychotherapeutin',
    district: 'Durlach',
    image: '37 - Dr. Brigitte Schmid-Hagenmeyer.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Nico<br>Krebs',
    placement: 38,
    birthYear: 1996,
    familyStatus: 'Ledig',
    job: 'Bartender / Barchef',
    district: 'Südstadt',
    image: '38 - Nico Krebs.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Sandra Dürr',
    placement: 39,
    birthYear: 1975,
    familyStatus: 'Verheiratet',
    job: 'Architektin',
    district: 'Grötzingen',
    image: '39 - Sandra Dürr.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Lukas Wagner',
    placement: 40,
    birthYear: 1996,
    familyStatus: 'Ledig',
    job: 'Fachreferent für Grundsätze und Prozesse',
    district: 'Neureut',
    image: '40 - Lukas Wagner.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Charlotte Mohr',
    placement: 41,
    birthYear: 1988,
    familyStatus: 'Ledig',
    job: 'Customer Success Manager',
    district: 'Innenstadt-Ost, Innenstadt-West, Südweststadt',
    image: '41 - Charlotte Mohr.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Prof. Dr. Dieter Daub',
    placement: 42,
    birthYear: 1943,
    familyStatus: 'Verheiratet',
    job: 'Arzt',
    district: 'Weststadt, Nordstadt',
    image: '42 - Prof. Dr. Dieter Daub.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Tonja<br>Čuić-Huber',
    placement: 43,
    birthYear: 1989,
    familyStatus: 'Verheiratet, 1 Kind',
    job: 'Konzertpianistin',
    district: 'Wolfartsweier',
    image: '43 - Tonja Čuić-Huber.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Aaron Zdarsky',
    placement: 44,
    birthYear: 2000,
    familyStatus: 'Ledig',
    job: 'Student',
    district: 'Hagsfeld, Waldstadt',
    image: '44 - Aaron Zdarsky.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Susanne Oppelt',
    placement: 45,
    birthYear: 1960,
    familyStatus: 'Verheiratet, 2 Kinder',
    job: 'Juristin, wissenschaftliche Mitarbeiterin',
    district: 'Durlach',
    image: '45 - Susanne Oppelt.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Leo Hammer<br>-schmidt',
    placement: 46,
    birthYear: 1954,
    familyStatus: 'Ledig',
    job: 'Rektor a.D.',
    district: 'Rintheim',
    image: '46 - Leo Hammerschmidt.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
    name: 'Dr. Martin Steffens',
    placement: 47,
    birthYear: 1979,
    familyStatus: 'Verheiratet, 3 Kinder',
    job: 'Leitender Regierungsdirektor',
    district: 'Rüppurr',
    image: '47 - Dr. Martin Steffens.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
  {
  name: 'Judith Marvi',
  placement: 48,
  birthYear: 1984,
  familyStatus: 'Verheiratet, 2 Kinder',
  job: 'Angestellte',
  district: 'Innenstadt-Ost, Innenstadt-West, Südweststadt',
    image: '48 - Judith Marvi.webp',
    statement: '',
    website_link: '',
    facebook_account: '',
    x_account: '',
    instagram_account: '',
  },
];

