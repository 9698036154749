import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ExampleComponent } from "./components/example/example.component";
import { NewsComponent } from "./components/news/news.component";
import { ListComponent } from "./components/list/list.component";
import { ProgramComponent } from "./components/program/program.component";
import { JobsComponent } from "./components/jobs/jobs.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/unsere-liste',
    pathMatch: 'full',
  },
  {
    path: 'aktuelles',
    component: NewsComponent,
    pathMatch: 'full',
    data: {
      title: 'Beispiel'
    }
  },
  /*{
    path: 'example',
    component: ExampleComponent,
    pathMatch: 'full',
    data: {
      title: 'Beispiel'
    }
  },*/
  {
    path: 'unsere-liste',
    component: ListComponent,
    pathMatch: 'full',
    data: {
      title: 'Unsere Liste'
    }
  },
  {
    path: 'unser-programm',
    component: ProgramComponent,
    pathMatch: 'full',
    data: {
      title: 'Unsere Liste'
    }
  },
  {
    path: 'mitmachen',
    component: JobsComponent,
    pathMatch: 'full',
    data: {
      title: 'Mitmachen'
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
